/*main*/

body {
    font-size: 1rem;
    font-family: Montserrat, sans-serif!important;
}

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
    display: none!important;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none!important;
}

/* Hiding reCaptcha v3 badge in the bottom right corner */
.grecaptcha-badge {
    visibility: hidden;
}

.container {
    max-width: 1440px;
    margin: auto;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-around;
    align-items: flex-start;
}

.container-column {
    flex-direction: column;
}

.stripe-white {
    max-width: 1440px;
    margin: auto;
    background: white;
    padding: 3rem 0;
}

.stripe-gray {
    max-width: 1440px;
    margin: auto;
    background: #F7F8F8;
    padding: 3rem 0;
}

.stripe-new-gray {
    max-width: 1440px;
    margin: auto;
    background: #F0F2F5;
    padding: 3rem 0;
}

.stripe-cold-gray {
    max-width: 1440px;
    margin: auto;
    background: #666f7a;
    padding: 3rem 0;
}

.stripe-dark-gray {
    max-width: 1440px;
    margin: auto;
    background: #45655E;
    padding: 3rem 0;
}

.footer-stripe {
    border-radius: 16px 16px 0 0;
}

.stripe-green {
    max-width: 1440px;
    margin: auto;
    background: #009966;
    padding: 2rem 0;
}

.subscription-stripe {
    border-radius: 16px;
    margin-bottom: 3rem;
}

.highlight-green {
    color: #009966!important;
}

.highlight-bright-green {
    color: #00CC33!important;
}

.centered {
    text-align: center;
}

.checkmark {
    width: 0.8rem;
    height: 0.6rem;
    margin-right: 1rem;
}

.fake-link {
    cursor: pointer;
    color: #009966;
}

a:hover, .fake-link:hover {
    color: #22AB5B!important;
}

/*h tags*/

h1 {
    font-size: 36px;
    font-weight: 600;
    color: #666F7A;
    padding: 0;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
    line-height: 42px;
}

.feature-title {
    font-size: 3.5rem;
    font-weight: 600;
    color: #009966;
    padding: 0;
    margin: 1.25rem auto 2.5rem auto;
    line-height: 4.125rem;
    text-align: center;
}

h2, .product-feature-title {
    font-size: 2.625rem;
    font-weight: 600;
    color: #21272B;
    padding: 0;
    margin: 0 auto;
    text-align: left;
}

.page-header {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    color: #666F7A;
}

h3, .product-header-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: #666F7A!important;
    padding: 0;
    margin-bottom: 0!important;
    text-align: center;
}

.product-header-white {
    font-weight: 600;
    line-height: 30px;
    padding: 0;
    margin: 0 auto 2.75rem auto;
    text-align: center;
    color: white;
    font-size: 24px;
}

h4 {
    font-size: 1.875rem;
    font-weight: 500;
    color: #666F7A;
    padding: 0;
    margin: 2rem auto 0 auto;
    line-height: 2.5rem;
}

h5 {
    font-size: 2.25rem;
    font-weight: 600;
    color: #21272B;
    padding: 0;
    text-align: center;
    margin: 1.5rem 0 0 0;
    line-height: 2.625rem;
}

/*buttons*/

.button-big {
    padding: 1rem 1.75rem;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
}

.button-small {
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    border-radius: 6px;
    margin: auto 0.5rem;
    cursor: pointer;
}

.button-green {
    color: white;
    background: #009966;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: all .5s ease;
}

.button-green:hover {
    color: white;
    background: #22AB5B;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: all .5s ease;
}

.button-white {
    color: #128756;
    background: white;
    border: 2px solid #128756;
    font-size: 1rem;
    cursor: pointer;
    transition: all .5s ease;
}

.button-white:hover {
    color: #22AB5B;
    background: white;
    border: 2px solid #22AB5B;
    font-size: 1rem;
    cursor: pointer;
    transition: all .5s ease;
}

.button-dark-green {
    color: white;
    background: #45655E;
    border: 2px solid #45655E;
    font-size: 1rem;
    cursor: pointer;
    transition: all .5s ease;
}

.button-dark-green:hover {
    color: #45655E;
    background: white;
    border: 2px solid #45655E;
    font-size: 1rem;
    cursor: pointer;
    transition: all .5s ease;
}

/*header*/

.header {
    width: 100%;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    position: fixed;
    top: 0;
    background: white;
    border-bottom: 1px solid #DAE0E6;
    padding-left: 1rem;
    padding-right: 1rem;
}

.header-block {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4.5rem;
}

@media (max-width: 1280px) {
    .header-block { padding: 0}
}

.trial-button {
    background: #009966;
    color: white;
    border: 0;
    height: 4.5rem;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 2.5rem;
    cursor: pointer;
    padding: 0 4.75rem;
}

.menu-item {
    color: #666F7A!important;
    text-decoration: none!important;
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    margin: auto 1.25rem;
    height: 73px;
    justify-content: center;
    align-items: center;
}

.menu-item-active {
    color: #009966;
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    margin: auto 1.25rem;
    text-decoration: none;
    height: 73px;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #009966;
    box-sizing: border-box;
}

.menu-item-mobile {
    width: 100%;
    padding: 1rem;
    color: #666F7A;
    font-size: 1.125rem;
    font-weight: 500;
    display: block;
    text-decoration: none;
    border-bottom: 1px solid #F5F5F5;
}

.no-border { border: none; }

.menu-item:hover, .menu-item-mobile:hover {
    color: #22AB5B;
}

.dropdown-item {
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 8rem;
    height: 3.125rem;
}

.dropdown-item a, .dropdown-item a:hover {
    color: #666F7A;
}

.dropdown-item:hover {
    background: #EFF1F5;
}

.mobile-menu-icon {
    font-size: 2rem;
    color: #009966;
    margin: auto 1.25rem auto 0;
}

/*footer*/

.footer {
    display: flex;
    justify-content: space-between;
    font-family: Inter, sans-serif;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    padding: 2rem;
    box-sizing: border-box;
    line-height: 1.875rem;
}

.livlet-logo {
    margin-right: 3.75rem;
}

.footer a {
    color: white;
    /*text-decoration: underline;*/
}

.footer-column {
    max-width: 18.75rem;
}

/*features*/

.feature-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    color: #666F7A;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 4.75rem 1rem auto 1rem;
}

.feature-column h4 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.details-text {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: #009966;
}

/*product*/

.product-container {
    /*max-width: 1440px;*/
    margin: auto;
    display: flex;
    padding: 4rem;
    align-items: center;
}

.container-left {
    flex-direction: row-reverse;
}

.container-right {
    flex-direction: row;
}

.container-column {
    flex-direction: column;
}

.product-description-row {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    color: #45655E;
    margin: 1rem 0;
    /*padding-right: 3.25rem;*/
    padding-left: 0.625rem;
}

.product-description-wrapper {
    width: 27rem;
    font-size: 20px;
    line-height: 24px;
    color: #21272B;
    margin: auto 6.25rem;
    flex-shrink: 0;
}

.product-description-wrapper h2 {
    font-size: 24px;
    line-height: 30px;
}

.product-description-wrapper p {
    font-size: 20px;
    margin: 1rem 0 2rem 0;
}

.product-reverse {
    flex-direction: row-reverse;
}

.image-wrapper svg  {
    max-width: 51.75rem;
    height: auto;
}

.product-image-wrapper svg, .product-image-wrapper img  {
    border-radius: 20px;
    max-width: 37.5rem!important;
    height: auto;
    box-shadow: 0 24px 32px rgba(8, 35, 48, 0.06);
}

/*pricing*/

.price-column {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: #BEBEC8;
}

.price-information {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 0 2.5rem;
    justify-content: space-between;
}

.price-information a {
    align-self: center;
}

.price-offer {
    width: 23.75rem;
    height: 14.375rem;
    border: 2px solid #EBEAED;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 0.5rem;
    box-sizing: border-box;
}

.price-free-label {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 3.625rem;
    line-height: 4.375rem;
    color: #009966;
}

.price-container { row-gap: 2.5rem; }

@media (max-width: 1225px) {
    .price-offer {
        width: 19rem;
    }

    .price-information {
        padding-right: 0;
        padding-left: 1.75rem;
    }
}

.price-offer-active {
    border: 2px solid #009966;
}

.price-type {
    text-transform: uppercase;
    color: #21272B;
    font-size: 0.875rem;
    font-weight: 600;
}

.price-wrapper {
    display: flex;
    margin: 1rem 0 0.75rem 0;
    align-items: flex-start;
}

.price-currency {
    color: #21272B;
    font-weight: 400;
    font-size: 1rem;
    padding-top: 0.75rem;
}

.price-tag {
    color: #21272B;
    font-size: 42px;
    font-weight: 700;
    margin: 0 1.5rem  0 0.75rem;
}

.price-description {
    font-size: 1.25rem;
    color: #BEBEC8;
    text-align: center;
}

.inactive {
    color: #BEBEC8;
}

/*faq*/

.slogan-image {
    background-image: url("https://www.livlet.com/img/hero-desktop.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 72px);
}

.first-promo-image {
    background-image: url("https://www.livlet.com/img/first50-desktop.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 842px;
    min-height: 650px;
}

.stripe-dark-gray img {
    vertical-align: top!important;
}

.stripe-rounded {
    border-radius: 16px;
}

.about-square {
    padding: 5rem;
    justify-content: flex-start;
    align-items: flex-start;
}

.about-square-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    color: #666F7A;
    font-size: 20px;
    line-height: 30px;
}

.about-square-text h4 {
    color: #666F7A;
    font-weight: 500;
    font-size: 24px;
    line-height: 42px;
    text-align: left;
    width: 100%;
}

/*email form*/

.email-form-container {
    margin: auto;
    width: 39.5rem;
    display: flex;
    flex-direction: column;
}

.email-form {
    width: 100%;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid white;
    display: flex;
}

.email-form input {
    background: #009966;
    color: white;
    border: 0;
    font-size: 20px;
    font-family: Montserrat, sans-serif;
}

.email-form .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.email-form .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.email-form .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.email-form .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: #009966!important;
}

.email-form input::placeholder {
    color: white;
    opacity: 0.5;
}

.email-form button, .email-form button:hover, .email-form button:active, .email-form button:focus, .email-form button:disabled {
    margin-left: 0.5rem;
    height: 3.5rem;
    border: 0;
    background: #009966;
    cursor: pointer;
}

.email-form-consent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 0.5rem;
    padding-top: 0.75rem;
    font-size: 1rem;
    color: white;
}

.email-form-consent input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
}

input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
}

.form-control + .form-control {
    margin-top: 1em;
}

input[type="checkbox"] {
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #45655E;
}

input[type="checkbox"] {
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #45655E;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

/*about us page*/

.faq-section-header {
    width: 100%;
    text-align: left;
    padding-bottom: 1rem;
    margin-top: 2.5rem;
}

.partners-container {
    max-width: 56.875rem;
    color: white;
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.875rem;
    text-align: center;
}

/*blog - header*/

.blog-header {
    display: flex;
    margin: 2.5rem 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.blog-header-links {
    display: flex;
    align-items: center;
    gap: 3.5rem;
}

.blog-header h1 {
    font-size: 30px;
    cursor: pointer;
    padding-bottom: 0.75rem;
    border-bottom: 3px solid white;
    transition: all .5s ease;
}

.blog-header h1:hover {
    color: #22AB5B;
}

.blog-header-selected {
    border-bottom: 3px solid #00B74F!important;
}

.blog-header a {
    color: #007F6E;
    font-size: 1.5rem;
}

.blog-posts-column {
    width: 80%;
}

.blog-sidebar-column {
    width: 20%;
}

.blog-latest-post {
    display: block;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 1rem;
    color: #6B7185;
}

.blog-latest-post:hover {
    color: #22AB5B;
}

/*blog - common*/

.blog-post-tags {
    color: #FCC800;
    font-size: 1.125rem;
    font-weight: 500;
}

.blog-post-data {
    color: #666F7A;
    font-size: 1.125rem;
    line-height: 1.875rem;
}

.more-posts-container {
    margin-bottom: 6.25rem;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    row-gap: 3rem;
}

.image-wrapper {
    width: 100%;
    display: flex;
    align-items: center!important;
    justify-content: center!important;
}

.image-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.latest-stories-header {
    color: #666F7A;
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: left;
}

.graphic-wrapper {
    display: grid;
    max-width: 100%;
}

.graphic-wrapper-mobile {
    display: none;
}

/*blog post - latest*/

.blog-post-latest {
    display: flex;
}

.blog-post-latest-image {
    max-width: 48.75rem;
}

.blog-post-latest-title {
    color: #666F7A;
    font-size: 30px;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.blog-post-latest-title a {
    color: #666F7A;
    text-decoration: none;
}

.blog-post-latest-description {
    display: flex;
    flex-direction: column;
    max-width: 28.625rem;
    margin-left: 2.75rem;
}

.blog-post-latest-summary {
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid #DAE0E6;
    margin-bottom: 1rem;
    color: #666F7A;
    font-size: 1.125rem;
    line-height: 2.125rem;
}

/*blog post column*/

.blog-post-column {
    display: flex;
    flex-direction: column;
    width: calc(80% / 3);
    justify-content: space-between;
    cursor: pointer;
}

.blog-post-inner {
    display: flex;
    flex-direction: column;
}

.blog-post-column-title {
    font-size: 20px;
    color: #666F7A;
    font-weight: 500;
    line-height: 30px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.blog-post-column-title a {
    color: #666F7A;
    text-decoration: none;
}

.blog-post-column-image {
    max-width: 25.625rem;
}

/*blog post - full*/

.blog-post-header {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.faq-post-header {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.blog-post-header a, .faq-post-header a {
    color: #007F6E;
    text-decoration: none;
    font-size: 16px;
    line-height: 45px;
}

.blog-post-top-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 39.375rem;
    margin: 1rem auto auto auto;
}

.blog-post-content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 3rem auto auto auto;
}

.blog-post-content-wrapper h2 {
    max-width: 39.375rem;
    font-size: 3.5rem;
    line-height: 4.5rem;
    color: #666F7A;
    margin-top: 6.25rem;
    margin-bottom: 3rem;
}

.blog-text-header {
    max-width: 39.375rem;
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding-bottom: 1rem;
}

.blog-text-paragraph {
    max-width: 39.375rem;
    width: 100%;
    text-align: left;
}

.blog-post-full-image {
    margin: auto;
    max-width: 39.375rem;
}

.blog-post-full-text {
    color: #666F7A;
    font-size: 1.125rem;
    line-height: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-post-full-data {
    color: #666F7A;
    font-size: 1.125rem;
    line-height: 34px;
    width: 100%;
    margin-top: 1.5rem;
    border-top: 1px solid #DAE0E6;
    padding-top: 1rem;
    margin-bottom: 3rem;
    text-align: left;
}

.blog-post-footer {
    width: 100%;
    text-align: center;
    margin-top: 4.375rem;
    margin-bottom: 3.125rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
}

.blog-post-footer a {
    color: #666F7A;
    text-decoration: none;
}

.blog-image {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.blog-quote-wrapper {
    width: 100%;
    background-color: #666f7a;
    color: white;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.container-trial {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 1.875rem;
    line-height: 3.25rem;
    color: #21272B;

    margin: 4.375rem auto;
    gap: 4.5rem;
}

.container-button {
    padding: 1.125rem 1.875rem!important;
    border-radius: 6px;
    font-weight: 500;
    font-size: 1.25rem!important;
    line-height: 1.625rem!important;
    display: flex;
    align-items: center;
    text-align: center;
}

.guidance-sign-up {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.875rem 1rem;
    background: #00B74F;
    border-radius: 6px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    transition: .5s all ease;
    border: none;
    margin: auto;
    margin-bottom: 1.5rem;
}

.guidance-sign-up:hover {
    background: #67C18B;
}

/*cookie toolbar*/

.cookie-toolbar {
    position: sticky;
    left: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: white;
    border-top: 1px solid #DAE0E6;
    padding: 1.5rem;
}

.cookie-toolbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
}

.cookie-row {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.text-button {
    border: none;
    color: #009966;
    text-decoration: underline;
    font-size: 1rem;
    background: white;
    padding: 0.25rem 1.25rem;
    cursor: pointer;
}

.ant-switch-checked {
    background: #009966!important;
}

.cookie-buttons {
    flex-shrink: 0;
}

.blog-subscribe-link {
    font-size: 1.5rem;
    text-decoration-line: underline;
    color: #007F6E;
    cursor: pointer;
}

.blog-subscribe-mobile {
    display: none;
}

.latest-stories-container {
    margin-top: 6.375rem
}

.sign-up-mobile {
    display: none;
}

.livlet-slogan {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 36px;
    line-height: 44px;
    color: white;
    max-width: 320px;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
    text-align: left;
}

.greeting-header {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #21272B;
    margin: 2rem auto 1rem auto;
}

.greeting-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #9D9DAC;
    text-align: center;
}

.section-header-green {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    color: #009966;
    margin: 1rem auto;
}

.login-button {
    width: 142px;
    height: 40px;
    border: 1px solid #DAE0E6;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    color: #21272B;
    background: white;
    cursor: pointer;
}

.free-trial-button, .free-trial-button:hover {
    font-family: Montserrat, sans-serif;
    width: 142px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #22AB5B;
    cursor: pointer;
}

.login-button:hover, .feature-button:hover {
    background: #22AB5B;
    transition: background-color 0.5s ease;
}

.submit-button {
    font-family: Montserrat, sans-serif !important;
    width: 142px;
    height: 40px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    color: #FFFFFF !important;
    background: #009966 !important;
    border-radius: 6px !important;
    border: 1px #009966 solid!important;
    cursor: pointer !important;
}

.submitted-button {
    background: #2A80E1!important;
    border: 1px #2A80E1 solid!important;
}

.submit-button:hover, .submitted-button:hover {
    background: #FFFFFF !important;
    color: #009966 !important;
    border: 1px #009966 solid!important;
}

.energy-data {
    font-size: 1rem;
}

.energy-data h2 {
    font-size: 1.875rem;
    width: 100%;
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.energy-data h3 {
    font-size: 1.75rem;
    width: 90%;
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.energy-data table {
    width: 90%;
    margin: 0 auto 2.5rem auto;
    border: none;
    font-size: 0.875rem;
}

.energy-data td {
    text-align: center;
    border: none;
    border-bottom: 2px solid #c0eed3;
}

.energy-data th {
    text-align: center;
    border: none;
}

.gray-cell {
    background: #f3f3f2;
}

.bordered-cell {
    border-bottom: 2px solid #c0eed3!important;
}

.ant-collapse {
    width: 100%;
    background: white!important;
    border: none!important;
}

.ant-collapse-item {
    border-bottom: 1px solid #DAE0E6!important;
}

.ant-collapse-item .ant-collapse-item-active {
    border-bottom: 1px solid #DAE0E6!important;
}

.ant-collapse-content-active {
    border-top: none!important;
}

.ant-collapse-arrow svg path {
    fill: #009966!important;
}

.ant-collapse-header {
    font-size: 1.25rem;
    font-weight: 600;
    color: #666F7A!important;
    padding-top: 0.75rem!important;
    padding-bottom: 0.75rem!important;
}

.ant-collapse-content-box {
    color: #6B7185!important;
    font-size: 1rem!important;
}

.message-form {
    width: 85%;
    margin: auto;
}

.message-form .ant-form-item  {
    width: 100%;
    margin: 0;
}

.message-form-label {
    font-size: 1.25rem;
    width: 250px;
}

.message-form input, .message-form textarea {
    display: flex;
    border: none;
    font-size: 1.25rem;
}

.message-form-line {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.message-form-label {
    width: 20%;
}

.message-form-item {
    width: 80%;
    display: flex;
}

.partner-text {
    max-width: 752px;
    margin: 3rem auto auto;
}

.partner-text p {
    width: 100%;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-align: center;
    color: #9D9DAC;
}

.partner-logos {
    display: flex;
    width: 100%;
    justify-content: center;
    column-gap: 4rem;
    align-items: center;
    margin: 3rem auto;
    padding: 0 32px;
}

.partner-logos img {
    height: 50px;
}

.social-media-buttons-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    width: 100%;
    justify-content: flex-start;
}

.container-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;

    max-width: 75%;
    margin: 1.5rem auto;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
}

.feature-button {
    border: 1px solid #666F7A;
    box-sizing: border-box;
    border-radius: 6px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    color: #21272B;
    background: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.square-wrapper, .square-wrapper-home {
    max-width: 1440px;
    margin: auto;
    display: flex;
    padding: 0;
    min-height: 37.5rem;
}

.square-wrapper-mobile {
    display: none;
}

.slogan-wrapper {
    position: absolute;
    left: 0;
}

.square {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square-light-gray {
    background: #F7F8F8;
}

.square-medium-gray {
    background: #F5F6FA;
}

.square-left {
    border-radius: 16px 0 0 16px;
}

.square-right {
    border-radius: 0 16px 16px 0;
}

.square-blue {
    background: #EFFAFE;
}

.square-dark-gray {
    background: #dededc;
}

.square-green {
    background: #009966;
    color: white;
}

.square-dark-green {
    background: #45655E;
    color: white;
}

.square-cold-gray {
    background: #f6f9f9;
    color: #2c3a44;
}

.square-warm-gray {
    background: #F7F8F8;
    color: #2c3a44;
}

.square-medium-gray {
    background: #F5F6FA;
    color: #45655E;
}

.square-content, .square-slogan {
    /*max-width: calc(1440px / 2);*/
    justify-content: flex-start;
    padding: 5rem;
}

.img-square img {
    max-width: 100%;
}

.square-graphic {
    padding: 3rem;
    flex-direction: column;
}

.square-start {
    justify-content: flex-start;
}

.square-end {
    justify-content: flex-end;
}

.home-square-text {
    font-weight: 600;
    font-size: 36px;
    line-height: 55px;
}

.mission-statement {
    max-width: 1290px;
    row-gap: 0.75rem;
}

.group-tagline {
    font-weight: 600;
    font-size: 17px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #00CC33;
    width: 100%;
    text-align: left;
}

.tagline-mobile {
    display: none;
}

.group-header {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #45655E;
    width: 100%;
    text-align: left;
    margin: 2.5rem 0;
}

.group-content {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #45655E;
    width: 100%;
    text-align: left;
}

.group-white {
    color: white;
}

.about-accent {
    color: #45655E;
    font-weight: 700;
}

.mission-statement span {
    width: 100%;
    color: white;
    text-align: center!important;
    font-size: 20px;
    line-height: 28px;
}

.email-arrow-button {
    display: flex;
    flex-shrink: 0;
}

.button-cta {
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0 1rem;
}

.button-cta:hover {
    font-size: 14px;
}

.button-cta-big {
    height: 60px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0 1rem;
}

.button-cta-big:hover {
    font-size: 20px;
}

.button-slogan, .button-slogan:hover {
    padding: 12px 42px;
    height: auto;
    margin-top: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.cta-free-trial {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    text-shadow: 0 0 10px #002A43, 0 0 30px rgba(0, 0, 0, 0.75);
}

.contact-button {
    position: fixed;
    z-index: 100;
    bottom: 0;
    margin-bottom: 4px;
    margin-left: 10px;
}

.contact-button svg {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));
    /*transition: all .5s ease;*/
}

.cta-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.contact-modal {
    padding: 16px;
    border-radius: 6px;
}

.contact-modal .ant-modal-header, .contact-modal .ant-modal-close-x {
    display: none;
}

.contact-modal .ant-modal-body {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.contact-form-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #45655E;
    width: 100%;
    text-align: center;
    padding: 6px;
    border-bottom: 1px solid #D6D6D6;
}

.contact-form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-form-wrapper .ant-form-item {
    margin-bottom: 8px;
}

.contact-form-row {
    width: 100%;
}

.contact-form-row-wrapper {
    width: 100%;
    display: flex;
    column-gap: 12px;
}

.contact-form-column {
    width: 50%;
}

.contact-form-label {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #21272B;
}

.contact-form-asterisk {
    color: #3f8ce4;
    padding-left: 5px;
}

.contact-form-send, .contact-form-cancel:hover {
    width: 100%;
    height: 40px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    background: #45655E!important;
    border: 1px solid #45655E!important;
    border-radius: 6px!important;
    font-weight: 500!important;
    font-size: 15px!important;
    line-height: 24px!important;
    color: #FFFFFF!important;
    transition: all .5s ease;
    margin-top: 11px;
}

.contact-form-cancel, .contact-form-send:hover {
    width: 100%;
    height: 40px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    background: #FFFFFF!important;
    border: 1px solid #45655E!important;
    border-radius: 6px!important;
    font-weight: 500;
    font-size: 15px!important;
    line-height: 24px!important;
    color: #45655E!important;
    transition: all .5s ease;
    margin-top: 11px;
}

.feature-container {
    justify-content: space-evenly;
}

.hero-banner {
    height: calc(100vh - 72px);
    display: flex;
}

.hero-banner-image {
    background-image: url("https://www.livlet.com/img/hero-desktop-2.webp");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 70vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4.375rem;
}

.hero-banner-text {
    width: 18.5rem;
    font-weight: 600;
    font-size: 2.75rem;
    line-height: 3.375rem;
    display: flex;
    color: #3F5D58;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2.5rem;
}

.hero-banner-text .highlight-green {
    font-size: 2.313rem;
    line-height: 2.813rem;
}

.hero-banner-button {
    cursor: pointer;
    border: none;
    padding: 1.125rem 1.875rem;
    background: #009966;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.625rem;
}

.hero-banner-button :hover {
    background: #22AB5B;
}

.hero-banner-sidebar {
    width: 30vw;
    background: #F7F8F8;
    display: flex;
    flex-direction: column;
    row-gap: 2.625rem;
    justify-content: center;
    align-items: center;
}

.hero-banner-sidebar-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 1.375rem;
}

.hero-banner-sidebar-text {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: #45655E;
    width: 21vw;
}

.hero-mobile {
    display: none;
}

@media (max-width: 1024px) {
    .hero-banner {
        flex-direction: column;
        height: auto;
    }

    .hero-banner-image {
        background-image: url("https://www.livlet.com/img/hero-mobile.webp");
        width: 100%;
        height: 35vh;
    }

    .hero-banner-text, .hero-banner-text .highlight-green {
        width: auto;
        text-align: center;
        align-items: center;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .hero-banner-sidebar {
        width: 100%;
        padding: 2.5rem;
    }

    .hero-desktop {
        display: none;
    }

    .hero-mobile {
        display: flex;
    }

    .hero-banner-sidebar-text {
        width: 70vw;
        font-size: 1.063rem;
        line-height: 1.313rem;
    }
}


/* adaptations for smaller screens */

@media (max-width: 1450px) {
    .image-wrapper svg, .product-image-wrapper svg, .product-image-wrapper img {
        max-width: 100%;
        max-height: 100%;
    }
}

@media (max-width: 1250px) {
    .image-wrapper svg, .product-image-wrapper svg, .product-image-wrapper img {
        width: auto;
    }
}

@media (max-width: 1280px) {
    .product-container {
        padding: 4.75rem 2rem;
    }

    .product-description-wrapper {
        margin: auto 1rem auto 3rem;
    }

    .livlet-logo {
        margin-right: 1rem;
    }
}

@media (max-width: 1160px) {
    .trial-button {
        padding: 0 3rem;
    }
}

/* for mobile */

.header-block-mobile {
    display: none;
}

.display-mobile, .slogan-mobile {
    display: none;
}

.social-media-buttons-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
}

@media(max-width: 1100px) {
    .header-block {
        display: none;
    }

    .header-block-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .feature-container-title {
        text-align: left;
        justify-content: flex-start!important;
        align-items: flex-start!important;
        padding-bottom: 1rem!important;
    }

    .feature-container-title h3 {
        margin: 0;
    }

    .feature-column {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        text-align: left;
        margin: 1rem;
        width: 100%;
        justify-content: flex-start;
        padding: 0!important;
    }

    .feature-container h4 {
        margin-left: 0;
        margin-top: 0;
        text-align: left;
    }

    .feature-column-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .feature-column svg { flex-shrink: 0; }

    .display-desktop {
        display: none!important;
    }

    .display-mobile {
        display: block;
    }

    .slogan-mobile {
        display: block!important;
    }

    .square-wrapper {
        flex-direction: column;
    }

    .square {
        width: 100%;
    }

    .square-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1.5rem;
        align-items: center;
    }

    .square-end, .square-start {
        justify-content: center;
    }

    .shapeshift-reverse {
        flex-direction: column-reverse;
    }

    .shapeshift-light-gray {
        background: #F7F8F8;
        color: #45655E;
    }

    .shapeshift-dark-green {
        background: #45655E;
    }

    .slogan-wrapper {
        position: relative;
        height: calc(100vh - 72px);
        display: flex;
        align-items: center;
        padding: 0 1.5rem 0 1.5rem;
    }

    .slogan-image {
        background-image: url("https://www.livlet.com/img/hero-mobile.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vh - 72px);
    }

    .first-promo-image {
        background-image: url("https://www.livlet.com/img/first50-mobile.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        max-height: 723px;
        min-height: 520px;
    }

    .square-slogan {
        width: 100%;
        max-width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .home-square-text {
        padding: 3rem 1.5rem;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    .square-left, .square-right, .footer-stripe, stripe-rounded {
        border-radius: 0;
    }

    .about-square {
        padding: 2rem
    }

    .container-cta {
        padding: 3rem 1.5rem;
        max-width: 100%;
        row-gap: 2rem;
    }

    .mission-statement {
        padding: 1rem;
    }

    .product-description-wrapper {
        width: 100%;
        margin: auto;
        padding: 1.5rem;
    }

    .product-image-wrapper {
        padding: 0 3rem 3rem 3rem;
    }

    .product-description-wrapper h2 {
        text-align: left;
    }

    .product-description-wrapper p {
        text-align: left;
    }

    .product-container {
        height: auto;
        margin: 2rem auto;
        padding: 0;
    }

    .container-left, .container-right {
        flex-direction: column;
    }

    .partner-logos {
        flex-direction: column;
        row-gap: 2rem;
    }

    .header {
        padding-right: 0;
    }

    .display-mobile {
        display: flex!important;
    }

    .display-desktop {
        display: none;
    }

    .container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
    }

    .footer {
        padding-top: 2.25rem;
        padding-bottom: 0;
        height: auto;
    }

    .product-header-title {
        font-size: 2.25rem;
        line-height: 6rem;
        margin-bottom: 2.5rem;
    }

    .greeting-title {
        font-size: 1.875rem;
        line-height: 2.375rem;
    }

    .footer-column {
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .product-description-row {
        padding-right: 0.625rem;
    }

    .padded-description-wrapper {
        padding-top: 4.688rem;
    }

    .padded-image-wrapper {
        padding-bottom: 4.688rem;
    }

    .header-title-condensed {
        font-size: 1.563rem;
        line-height: 1.875rem;
    }

    .condensed-line-height {
        font-size: 2.188rem;
        line-height: 2.625rem;
    }

    .product-header-white {
        margin-bottom: 1rem;
    }

    h1, h4 {
        text-align: center;
    }

    .product-reverse {
        flex-direction: column;
    }

    .price-offer {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .price-information {
        padding: 1rem;
        height: auto;
    }

    .price-information a {
        margin-top: 1rem;
    }

    .product-feature-title {
        margin-top: 2rem;
        text-align: center;
        margin-bottom: 1.875rem;
    }

    .email-form-container {
        width: 100%;
        margin-top: 2rem;
        padding-left: 2.125rem;
        padding-right: 2.125rem;
    }

    .email-form input {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .email-arrow-button svg {
        width: 10px;
        height: 26px;
    }

    .email-form button {
        height: 1rem;
    }

    .blog-post-tag-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .blog-post-full-data {
        margin-bottom: 1rem;
    }

    .header {
        position: fixed;
        top: 0;
        background: white;
        width: 100%;
        border-bottom: 1px solid #DAE0E6;
    }

    .header-block {
        justify-content: space-between;
        display: none;
    }

    .feature-column {
        width: 100%;
        padding: 0 1.5rem;
    }

    .blog-title {
        font-size: 3.125rem;
        line-height: 3.75rem;
        /*width: 12.5rem;*/
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0!important;
    }

    .latest-stories-header {
        font-size: 2.188rem;
        line-height: 2.813rem;
        margin-bottom: 0;
        text-align: center;
    }

    .more-posts-container {
        margin-bottom: 1rem;
    }

    .blog-header {
        flex-direction: column;
    }

    .blog-post-footer {
        margin-top: 2rem;
    }

    .blog-post-latest {
        flex-direction: column;
        width: 100%
    }

    .blog-post-latest-title {
        font-size: 2.188rem;
        line-height: 2.813rem;
        text-align: left;
    }

    .blog-post-full-wrapper {
        margin-top: 1rem;
    }

    .blog-post-full-content {
        margin-top: 0;
    }

    .blog-post-latest-description {
        margin-left: auto;
        margin-top: 1.5rem;
        max-width: 46.25rem;
    }

    .blog-posts-column, .blog-sidebar-column {
        width: 100%;
    }

    .blog-post-column-image {
        height: auto;
    }

    .blog-post-column {
        margin-right: 0;
        width: 100%;
        max-width: 20rem;
    }

    .blog-post-header {
        display: none;
    }

    .blog-return-link-mobile {
        display: flex;
        align-items: center;
        color: #007F6E;
    }


    /*about us page*/

    .partners-container {
        font-size: 1.563rem;
        line-height: 1.875rem;
    }

    .blog-subscribe-link {
        display: none;
    }

    .blog-subscribe-mobile {
        margin: 2rem 0 0 1rem;
        display: flex;
        justify-content: center;
    }

    .latest-stories-container {
        margin-top: 3.75rem;
    }

    .product-image-wrapper {
        padding: 0 1rem;
    }

    .sign-up-desktop {
        display: none;
    }

    .sign-up-mobile {
        margin-top: 1rem;
        display: flex;
    }

    .button-slogan {
        margin-top: 20px;
    }

    .group-header {
        margin-top: 1rem;
    }
}

@media (max-width: 800px) {
    .cookie-toolbar-wrapper {
        flex-direction: column;
    }

    .cookie-buttons {
        margin-top: 1rem;
        display: flex;
    }

    .blog-post-content-wrapper h2 {
        margin-top: 3rem;
        margin-bottom: 1rem;
        font-size: 2.5rem;
        line-height: 3.5rem;
    }

    .message-form-line {
        flex-direction: column;
    }

    .message-form-label {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .message-form-item {
        width: 100%;
    }

    .product-image-wrapper svg, .product-image-wrapper img  {
        max-width: 100%!important;
    }

    .square-wrapper-home {
        flex-direction: column;
    }

    .mobile-reverse {
        flex-direction: column-reverse;
    }

    .square-wrapper, .square-wrapper-home {
        min-height: auto;
    }

    .square-graphic {
        padding: 2rem 1.5rem 3rem 1.5rem;
    }

    .group-tagline {
        font-size: 0.75rem;
        line-height: 0.875rem;
    }

    .group-header {
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin: 1rem;
    }

    .group-content {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .square-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;
        padding: 2.25rem 1.5rem;
    }

    .graphic-wrapper-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;
    }

    .square-wrapper-desktop {
        display: none;
    }

    .square-wrapper-mobile {
        display: flex;
    }
}

@media (max-width: 480px) {
    .slogan-wrapper {
        align-items: flex-end;
        padding: 0 1rem 3rem 1rem;
    }

    .livlet-slogan {
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 10px #002A43;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
    }

    .cta-wrapper {
        align-items: center;
    }
}

.calc-wrapper {
    display: flex;
    column-gap: 0.5rem;
    max-width: 1100px;
    margin: auto;
    padding-bottom: 3rem;
}

.calc-section-wrapper {
    width: 50%;
    background: white;
    border-radius: 8px;
    padding: 0 1rem;
}

.calc-section-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #EFF1F5;
    column-gap: 0.5rem;
    font-size: 1.625rem;
    font-weight: 700;
}

.calc-field-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #666F7A;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
}

.calc-input {
    width: 100px!important;
    border: 1px solid #dae0e6;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 6px;
    color: rgb(107, 113, 133);
    height: 24px;
}

.calc-input .ant-input-number {
    display: flex;
    align-items: center;
}

.calc-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 1.5rem;
}

.calc-output {
    width: 100%;
    color: #666F7A;
    background: #EFF1F5;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 0.5rem;
    height: 2.5rem;
}

.calc-cell-wrapper {
    display: flex;
    column-gap: 0.5rem;
    justify-content: space-between;
    width: 100%;
}

.calc-cell {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.calc-shower-cell {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #666F7A;
    width: 123px;
}

.calc-shower-cell img {
    margin-bottom: 10px;
}

.calc-input-yellow .ant-input {
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #666F7A;
    background: #FEF9EA;

}

.calc-assumption-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #666F7A;
    margin-bottom: 6px;
    column-gap: 0.75rem;
}

.calc-right-shift-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    column-gap: 1rem;
}

.calc-assumption-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #666F7A;
}

.calc-assumption-button {
    cursor: pointer;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #2A80E1;
    margin-left: 0.75rem;
}

.calc-shower-flow-button {
    background: #67C18B;
    border-radius: 6px;
    padding: 4px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    height: 48px;
    border: none;
    text-align: left;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.calc-select-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #C7C9C7;
    background: #F5F6FA;
    padding: 0.563rem 0.5rem;
    height: 2.5rem;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    width: 196px;
}

.calc-select-label {
    color: #8E8E89;
    border-right: 1px solid #d4dadc;
    padding-right: 0.75rem;
    margin-right: 5px;
}

.calc-result-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666F7A;
}

.calc-result-value {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    color: #666F7A;
    flex-shrink: 0;
}

.calc-upper-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 450px;
}

.calc-lower-section {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #EFF1F5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 1rem;
}

.calc-saving-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666F7A;
}

.calc-saving-value {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    color: #21272B;
}

.calc-calculate-button {
    padding: 12px 24px;
    background: #2A80E1;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: 2px solid #2A80E1;
}

.calc-calculate-button:hover {
    background: white;
    color: #2A80E1;
    border: 2px solid #2A80E1;
}

.calc-dropdown {
    padding: 16px;
    width: 300px;
    background: #236EC2;
    box-shadow: 0 2px 4px 1px rgba(30, 32, 37, 0.05), 0 5px 12px rgba(26, 28, 31, 0.12);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.calc-dropdown a {
    word-break: break-all;
}

.calc-dropdown a {
    color: white;
    text-decoration: underline;
}

.calc-assumptions-mobile {
    display: none;
}

@media (max-width: 800px) {
    .calc-wrapper {
        flex-direction: column;
        row-gap: 1rem;
    }

    .calc-section-wrapper {
        width: 100%;
    }

    .calc-button-wrapper {
        justify-content: flex-start;
        padding-bottom: 1rem;
    }

    .calc-assumptions-desktop {
        display: none;
    }

    .calc-assumptions-mobile {
        display: block;
    }
}

@media (max-width: 500px) {
    .calc-upper-section {
        height: auto
    }

    .calc-cell-wrapper {
        flex-direction: column;
    }
    .calc-cell {
        width: 100%;
    }

    .calc-shower-flow-button {
        margin-top: 1rem;
    }

    .showerhead-wrapper {
        flex-direction: row!important;
    }

    .showerhead-wrapper img, .calc-shower-cell {
        width: 95px;
    }
}

.home-carousel {
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
}

.container-trial-text {
    font-size: 1.875rem;
    line-height: 3.25rem;
}

@media (max-width: 1024px) {
    .container-trial-text {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .container-partners-trial {
        gap: 2rem;
    }

    .partners-wrapper {
        padding: 0 1.5rem;
    }
}

/*cookiebot customization*/

#CybotCookiebotDialogBodyButtonsWrapper, #CybotCookiebotDialogFooter {
    display: flex!important;
    flex-direction: row-reverse!important;
    justify-content: center!important;
    column-gap: 1rem!important;
}

#CybotCookiebotDialogBodyEdgeMoreDetails, .CybotCookiebotDialogBodyEdgeMoreDetails {
    margin-top: 0!important;
    padding-top: 0!important;
}

#CybotCookiebotDialogBodyContent {
    padding-top: 0.625rem!important;
    min-height: auto!important;
    line-height: 1.2rem!important;
}

.CybotCookiebotDialogContentWrapper {
    align-items: center!important;
}

.CybotCookiebotDialogBodyButton {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    width: 150px!important;
    height: 46px!important;
    margin: 0!important;
}

/*#CybotCookiebotDialogHeader {*/
/*    padding-bottom: 0!important;*/
/*}*/

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background: #0C2A41!important;
    color: white!important;
}

.CybotCookiebotDialogBodyBottomWrapper {
    margin-top: 0!important;
    padding-right: 1.055rem!important;
    width: 100%!important;
    justify-content: flex-end!important;
}

#CybotCookiebotDialogBodyLevelButtonCustomize {
    display: none!important;
}

@media (max-width: 768px) {
    #CybotCookiebotDialogBodyContent {
        padding-top: 0!important;
        padding-bottom: 0!important;
    }

    #CybotCookiebotDialogBodyEdgeMoreDetails {
        margin: 0!important;
    }
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    display: none!important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    color: #0C2A41!important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer {
    min-height: auto!important;
}

.guidance-measure-container {
    max-width: 39.375rem;
    width: 100%;
    text-align: left;

    font-weight: 400;
    /*font-size: 1.125rem;*/
    /*line-height: 1.5rem;*/
    /*color: #21272B;*/
}

.guidance-measure-header {
    font-weight: 600;
    /*margin-bottom: 0.25rem;*/
}

#categoryFindMore {
    overflow-wrap: break-word;
}

.announcement-container-title {
    font-weight: 600;
    font-size: 1.05rem;
    line-height: 1.31rem;
    letter-spacing: 2px;
    color: #00CC33;

    margin-bottom: 2rem;
}

.announcement-container-headline {
    font-weight: 600;
    margin-bottom: 2rem;
}

.announcement-container-content {
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 2rem;
    color: #3E5D58;
    text-align: justify;
}

.announcement-container-content p {
    margin-bottom: 2rem;
    text-align: justify;
}

.announcement-container-content .footer-text {
    color: #00CC33;
    font-weight: 600;
}

@media (max-width: 768px) {
    .announcement-container-title {
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-bottom: 1rem;
    }

    .announcement-container-headline {
        margin-bottom: 1rem;

    }

    .announcement-container-content {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .announcement-container-content p {
        margin-bottom: 1rem;
    }
}